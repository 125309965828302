<template>
  <fragment> 
    <store ref="child" v-if="role.isVolvo"/>
    <storeDefault ref="child" v-else />
  </fragment> 
</template>

<script>
import Vue from 'vue'
import {getRole} from '@/utils/getRole';
import store from './store.vue';
import storeDefault from './storeDefault.vue';


export default {
  data() {
      return {
        role: getRole(),
      }
  },
  components:{
    store,
    storeDefault,
  },
  methods: {
    getDataBySearch(q) {
      this.$refs.child && this.$refs.child.getDataBySearch(q);
    },
  },
  created() {
  },
  mounted () {
  },
}
</script>

<style lang="less">

</style>


