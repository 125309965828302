<template>
  <div class="box-core-default" v-if="coreData">
    <div class="box-key">
      <p>
      <strong>{{coreData.total.value}}</strong>
      <span>{{coreData.total.title}}（{{coreData.total.unit}}）</span>
      </p>
      <p>
      <strong>{{coreData.match_rate.value || 0}}{{coreData.match_rate.unit}}</strong>
      <span>{{coreData.match_rate.title}}</span>
      </p>
    </div>
    <div class="box-gs">
      <p>
      <strong class="a">{{coreData.score.value || 0}}</strong>
      <span class="a-p">{{coreData.score.title}}</span>
      </p>
      <p class="flag">=</p>
      <p>
      <strong>{{coreData.in_score.value || 0}}</strong>
      <span>{{coreData.in_score.title}}</span>
      </p>
      <p class="flag">+</p>
      <p>
      <strong>{{coreData.out_score.value || 0}}</strong>
      <span>{{coreData.out_score.title}}</span>
      </p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { postForm } from '@/api'

export default {
    props: {
    },
    data() {
      return {
        coreData: null,
      }
    },
    methods: {
      loadData(params) {
        //const params = {}
        postForm('/api/bsa_board/index', params).then(res => {
          this.coreData = res.data;
        });
      }
    },
    computed: {
    }
}
</script>
<style lang="less" scope>
.box-core-default {
  margin: 0.5rem 0.75rem;

  p {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .box-key {
    display: flex;
    background: #fff;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    p {
      padding: 1rem 0;
      flex: 1;
      &:first-child {
        border-right: 1px #EFEFEF solid;
      }
    }
    strong {
      color: #000;
      height: 1.5rem;
      line-height: 1.5rem;
      display: block;
      font-size: 0.9rem;
    }
    span {
      color: #788798;
    }
  }
  .box-gs {
    display: flex;
    background: #0070C0;
    color: #fff;
    padding: 0 0.6rem 0.6rem 0.6rem;
    align-items: center;
    border-radius: 0.1rem;
    p {
      max-width: 4rem;
      white-space: pre-wrap;
      opacity: 0.7;

      &:first-child {
        max-width: 4.5rem;
        opacity: 1;
      }
    }
    strong {
      height: 2.5rem;
      line-height: 2.5rem;
      display: block;
      font-size: 1rem;
      font-weight: 300;
      &.a {
        font-size: 1.5rem;
      }
    }
    span {
      font-size: 0.6rem;

      &.a-p {
        font-size: 0.7rem;
      }
    }
    
    .flag {
      padding: 0 0.5rem;
      flex: 1;
    }
  }
}
</style>
